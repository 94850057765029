html, body{
  height: 100%;
  min-height: 100%;
}
button{
  white-space: nowrap;
}
#root{height: 100%}
.App {
  text-align: center;
  overflow: auto;
  height: 100%;
}
::-webkit-scrollbar {   
  z-index: 999999;
  width: 8px;
  height: 8px;
}
/*  */
::-webkit-scrollbar-track {
  background: #FF305340;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #FF3053;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555,
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
/* .playerContainer > div > div{
  border: 1px solid #ccc
} */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

*{
  word-break: break-word;
}

img.square_img{
  width: 60px !important;
  height: 60px !important;
  aspect-ratio: 1/1 !important
}
.templateContainer {
  /* text-align: center; */
  margin: 0 auto;
  justify-content: center;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  max-width: 700px !important;
  position: relative 
}
@media screen and (min-width: 1500px) and  (max-width: 2500px){
  .templateContainer{
    max-width: 900px !important;
  }

}
@media screen and (max-width: 600px){
  .templateContainer{
    max-width: 320px !important;
  }
}
.templatesCount{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.templatesCount h3{
  font-size: 18px;
  font-weight: 500;
  margin: 0px 0 10px;
}